import React from 'react';

import Layout from '../components/Layout';
import Band from '../components/Band/Band';
import { Container } from '../components/Grid/Grid';
import SEO from '../components/SEO';

const TermsPage = () => (
    <Layout>

        <SEO title="Thank You" description="Thanks for contacting us." />

        <Band size="lg">
            <Container>

                <h1>Thank You</h1>
                <p>Thank you. We will be in touch shortly.</p>

            </Container>
        </Band>

    </Layout>
);

export default TermsPage;
